import React, { useState, createContext, useEffect } from 'react';
import { api, createSession } from '../services/Api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState(null);

    const [equipment, setEquipment] = useState(null);

    const [patient, setPatient] = useState(null);

    const [expand, setExpand] = useState("false");

    const login = async (user, password) => {
        setLoading(true);
        const response = await createSession(user, password);
        if (response.authenticated) {
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('token', response.token);
            api.defaults.headers.Authorization = `Bearer ${response.token}`;
            setUser(response.user);
        } else {
            localStorage.setItem('user', null);
            setUser(null);
        }
        setLoading(false);
        return response.authenticated;
    };

    const selectEquipment = async (id) => {
        setLoading(true);
        let equip = {id: id};
        localStorage.setItem('equipment', JSON.stringify(equip));
        setEquipment(id);
        setLoading(false);
    };

    const logout = () => {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('patient');
        localStorage.removeItem('equipment');
        setUser(null);
        setPatient(null);
        setEquipment(null);
    };

    const reloadStates = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (token !== undefined && token !== null) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }

        const user = localStorage.getItem('user');
        if (user !== undefined && user !== null) {
            setUser(JSON.parse(user));
        }

        const patientStr = localStorage.getItem('patient');
        if (patientStr !== undefined && patientStr !== null) {
            const patient = JSON.parse(patientStr)
            setPatient(patient);
        }

        const equipStr = localStorage.getItem('equipment');
        if (equipStr !== undefined && equipStr !== null) {
            const equip = JSON.parse(equipStr)
            setEquipment(equip.id);
        }
        setLoading(false);
    };

    const expandSidebar = () => {
        (JSON.parse(expand) ? setExpand("false") : setExpand("true"))
    }

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (token !== undefined && token !== null) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }

        const user = localStorage.getItem('user');
        if (user !== undefined && user !== null) {
            setUser(JSON.parse(user));
        }

        const patientStr = localStorage.getItem('patient');
        if (patientStr !== undefined && patientStr !== null) {
            const patient = JSON.parse(patientStr)
            setPatient(patient);
        }

        const equipStr = localStorage.getItem('equipment');
        if (equipStr !== undefined && equipStr !== null) {
            const equip = JSON.parse(equipStr)
            setEquipment(equip.id);
        }

        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{
            authenticated: !!user,
            loading,
            reloadStates,
            user,
            login,
            logout,
            patient,
            expand,
            expandSidebar,
            equipment,
            selectEquipment
        }}>
            {children}
        </AuthContext.Provider>
    )
}