import { styled } from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  &:hover span{
    visibility: visible;
    cursor: default;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 100px;
  background-color: rgba(183,183,183,0.7);
  color: #fff;
  font-size: 10px;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  //position
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -50px;   // width / 2

`;

const Tooltip = ({ children, text}) => {
    return (
        <TooltipContainer>
            <TooltipText>{text.toUpperCase()}</TooltipText>
            {children}
        </TooltipContainer>
    )
}

export default Tooltip