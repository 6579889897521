import React, { useState } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import SideBar from '../components/SideBar';




function HomePage() {
  const [tag, setTag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!tag) {
      setErrorMessage("Preencha todos os campos");
      return;
    } else {
      setErrorMessage("");
      navigate("/patient");
    }
  }

  return (
    <div className='app'>
      <SideBar/>
    </div>
  )

  /*
  return (
    <div className='app'>
      <div id='home-page' className='home-page'>
        <h1 className='home-page-title'>Buscar TAG</h1>
        <div className="home">
          <form className='home-form' onSubmit={handleSubmit}>
            <p className='home-form-message'>{errorMessage}</p>
            <label htmlFor='tag-home' className='home-form-tag'>TAG:</label>
            <input
              id='tag-home'
              type='text'
              placeholder='TAG'
              name='tag-home'
              onChange={(e) => setTag(e.target.value)}
              value={tag}
            />
            <button type='submit' className='home-form-button'>Buscar</button>
          </form>
        </div>
      </div>
    </div>
  )
  */
}

export default HomePage
