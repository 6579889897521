import React, { useContext, useState } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import { deleteExamForId, deletePatientForId } from '../services/Api.js';
import { ListItem, ListItemLink, ListItemText } from './Common.js';
import { ExamListItemText } from '../pages/ExamPage.js';
import { getExamImg } from '../services/Api.js';
import viewIcon from "../images/eye.png";
import downloadIcon from "../images/download.png";
import deleteIcon from "../images/excluir.png";
import { ModalAlert } from './Modal';
import Tooltip from './Tooltip';

const ExamListItemTextFirst = styled.p`
    display: flex;
    align-items: center;
	justify-content: left;
	font-size: 18px;
    padding-left: 30px;
`;

const ListItemButtons = styled.div`
    display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 18px;

    & :hover {
        cursor: pointer;
    } 
`;

const ListItemIcon = styled.img`
    height: 20px;
    width: 20px;
`;

const Exam = ({ exam, openModal, modalImg, imgTitle, reloadList, examLength, patientId, patientName }) => {
    const { logout } = useContext(AuthContext);

    const [modalAlert, setModalAlert] = useState(false);

    const [deleteText, setDeleteText] = useState("");

    const navigate = useNavigate();

    let imgExam = "";

    const blobToImage = (img) => {
        return new Promise(resolve => {
            const buff = Buffer.from(img[0].img.data); // Node.js Buffer
            const blob = new Blob([buff]); // JavaScript Blob
            const url = URL.createObjectURL(blob);
            imgExam = url;
        })
    };

    const blobToFile = (img) => {
        return new Promise(resolve => {
            const buff = Buffer.from(img[0].img.data); // Node.js Buffer
            const blob = new Blob([buff]); // JavaScript Blob
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `${patientName}-${exam.tipo_exame}-${exam.lado_olho}-${data}-${hora}.png`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
    };

    const loadImg = async (download) => {
        document.getElementById("app").style.cursor = "wait";
        let res = await getExamImg(exam.id, exam.id_tag);
        if (res === 401) {
            logout();
            navigate("/login");
        } else {
            if (download) {
                blobToFile(res);
            } else {
                blobToImage(res);
            }
        }
        document.getElementById("app").style.cursor = "default";
    };

    const openImg = async () => {
        await loadImg(false);
        imgTitle(`${exam.tipo_exame}-${exam.lado_olho}-${data}-${hora}`)
        modalImg(imgExam);
        openModal();
    };

    const downloadImg = async () => {
        await loadImg(true);
    };

    const deleteExam = async () => {
        if(examLength == 1){
            let res = await deleteExamForId(exam.id);
            res = await deletePatientForId(patientId);
            if (res === 401) {
                logout();
                navigate("/login");
            } else {
                navigate("/patient");
            }
        }else{
            let res = await deleteExamForId(exam.id);
            if (res === 401) {
                logout();
                navigate("/login");
            } else {
                reloadList();
            }
        }
        
    };

    const openAlert = () => {
        if(examLength == 1){
            setDeleteText("Último exame do paciente, ao excluir o exame o paciente também será excluído. Deseja excluir o exame?");
            setModalAlert(true);
        }else{
            setDeleteText("Deseja excluir o exame?");
            setModalAlert(true);
        }
    };

    const data = exam.data_hora.slice(8, 10) + exam.data_hora.slice(4, 8) + exam.data_hora.slice(0, 4);
    const hora = exam.data_hora.slice(11, 19);
    return (
        <ListItem>
            <ListItemLink >
                <ExamListItemText>
                    <ExamListItemTextFirst>{exam.tipo_exame}</ExamListItemTextFirst>
                    <ListItemText>{exam.lado_olho}</ListItemText>
                    <ListItemText>{data} / {hora}</ListItemText>
                    <ListItemText>{exam.nome_medico}</ListItemText>
                    <ListItemButtons>
                        <Tooltip text={"visualizar"}><ListItemIcon onClick={() => openImg()} src={viewIcon} alt='visualizar' aria-label='visualizar'/></Tooltip>
                        <Tooltip text={"baixar"}><ListItemIcon onClick={() => downloadImg()} src={downloadIcon} alt='baixar' aria-label='baixar'/></Tooltip>
                        <Tooltip text={"excluir"}><ListItemIcon onClick={() => openAlert()} src={deleteIcon} alt='excluir' aria-label='excluir'/></Tooltip>
                    </ListItemButtons>
                </ExamListItemText>
            </ListItemLink>
            <ModalAlert
                showModal={modalAlert}
                setShowModal={setModalAlert}
                confirm={deleteExam}
                confirmText={"Excluir"}
                text={deleteText}
            ></ModalAlert>
        </ListItem>
    )
};

export default Exam;