import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import listIcon from "../images/lista-azul.png";
import patientIcon from "../images/avatar-branco.png";
import settingsIcon from "../images/configuracao-branco.png";
import logoutIcon from "../images/sair-branco.png";
import clinicIcon from "../images/clinica-branco.png";
import eyetecLogo from "../images/eyetecLogo.png";
import minimizeIcon from "../images/seta-esquerda-azul.png";


const Sidebar = styled.nav`
    background: linear-gradient(353deg, rgba(31,58,114,1) 0%, rgba(0,64,158,1) 30%, rgba(31,58,114,1) 100%);
    width: 100%;
    min-width: 100%;
    height: 100vh;
    min-height: 314px;
    display: grid;
    grid-template-rows: 52px minmax(210px, calc(100vh - 104px)) 52px;
`;

const SidebarExpand = styled.div`
    width: 100%;    
    display: flex;
    align-items: center;
    justify-content: ${props => JSON.parse(props.$expand) ? "space-between" : "center"};
    border-bottom: 1px solid white;
    background-color: white;
`;

const SidebarHello = styled.p`
    display: ${props => JSON.parse(props.$expand) ? "flex" : "none"};
    font-size: 30px;
    color: #00409e;
    cursor: pointer;
    padding-left: 5px;
`;

const SidebarList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: ${props => JSON.parse(props.$expand) ? "stretch" : "center"}; ;
    height: 100%;
    min-height: 210px;
`;

const SidebarListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => JSON.parse(props.$expand) ? "left" : "center"};
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid white;
    
    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid gray;
    }
`;

const SidebarListItemText = styled.p`
    display: ${props => JSON.parse(props.$expand) ? "flex" : "none"};
    font-size: 25px;
    color: white;
    cursor: pointer;
`;

const SidebarListItemIcon = styled.img`
    margin: 10px;
    height: 30px;
    cursor: pointer;
`;

const SidebarLogo = styled.div`
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
`;

const SidebarLogoImg = styled.img`
    ${props => JSON.parse(props.$expand) ? "height: 100%;" : "width: 100%;"};
    margin: 0 auto 0 auto;
    background-color: white;
`;

const SideBar = () => {
    const { logout, expand, expandSidebar, user } = useContext(AuthContext);

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    }

    return (
        <Sidebar>
            <SidebarExpand $expand={expand}>
                <SidebarHello $expand={expand}>Olá, {user.name.split(' ')[0]}</SidebarHello>
                <SidebarListItemIcon src={JSON.parse(expand) ? minimizeIcon : listIcon} alt='ícone lista' onClick={() => expandSidebar()} />
            </SidebarExpand>
            <SidebarList $expand={expand}>
                <SidebarListItem $expand={expand} onClick={() => navigateTo("/patient")}>
                    <SidebarListItemIcon src={patientIcon} alt='ícone avatar' />
                    <SidebarListItemText $expand={expand}>Pacientes</SidebarListItemText>
                </SidebarListItem>
                <SidebarListItem $expand={expand} onClick={() => navigateTo("/clinic")}>
                    <SidebarListItemIcon src={clinicIcon} alt='ícone clinica' />
                    <SidebarListItemText $expand={expand}>Clínica</SidebarListItemText>
                </SidebarListItem>
                <SidebarListItem $expand={expand} onClick={() => navigateTo("/patient")}>
                    <SidebarListItemIcon src={settingsIcon} alt='ícone configuração' />
                    <SidebarListItemText $expand={expand}>Configuração</SidebarListItemText>
                </SidebarListItem>
                <SidebarListItem $expand={expand} onClick={() => logout()}>
                    <SidebarListItemIcon src={logoutIcon} alt='ícone sair' />
                    <SidebarListItemText $expand={expand}>Sair</SidebarListItemText>
                </SidebarListItem>
            </SidebarList>
            <SidebarLogo>
                <SidebarLogoImg $expand={expand} src={eyetecLogo} />
            </SidebarLogo>
        </Sidebar>
    )
}

export default SideBar;