import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Patient from '../components/Patient.js';
import { SearchPatient } from '../components/Search.js';
import { getAllPatients, getEquipmentsClinic } from '../services/Api.js';
import { AuthContext } from '../contexts/auth';
import App from '../components/App.js';
import { Page, PageHead, PageTitle, PageList, ListHeader, PageHeadBar, PageHeadSelect, PageHeadOption } from '../components/Common.js';

//as páginas utilizam styled components
export const PatientListItemText = styled.div`
  display: grid;
	grid-template-columns: minmax(300px, 70%) minmax(100px, 15%) minmax(100px, 15%);
  align-items: center;
  justify-content: space-between;
`;

const PatientListItemTextFirst = styled.div`
  display: flex;
	align-items: center;
	justify-content: left;
	font-size: 18px;
  padding-left: 30px;
`;

const PatientListItemTextInfo = styled.div`
  display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
`;

const PatientPage = () => {
  const { user, logout, equipment, selectEquipment } = useContext(AuthContext); //contexto basicamente são variaveis de sessão

  const [equipmentList, setEquipmentList] = useState([]);

  const [patientList, setPatientList] = useState([]);

  const [search, setSearch] = useState("");

  const [filter, setFilter] = useState("name");

  const [sort, setSort] = useState("Asc");

  const navigate = useNavigate();

  const loadPatients = async (id_clinica) => { //função para enviar a requisição para o backend e carregar os dados
    document.getElementById("app").style.cursor = "wait";
    if (user) {
      let res = await getAllPatients(id_clinica); //requisição para a api
      if (res === 401) { //indica que o usuario não está autenticado e redireciona para o login
        logout();
        navigate("/login");
      } else {
        setPatientList(res);
        selectEquipment(id_clinica);
      }
    }
    document.getElementById("app").style.cursor = "default";
  };

  useEffect(() => { //chamado após carregar a página
    const loadData = async () => {
      document.getElementById("app").style.cursor = "wait"; //cursor do mouse de carregando
      if (user) {
        let equipments = await getEquipmentsClinic(user.clinic); //requisição para a api
        if (equipments === 401) { //indica que o usuario não está autenticado e redireciona para o login
          logout();
          navigate("/login");
        } else { 
          setEquipmentList(equipments);
          if (equipments.some(e => e.id_tag == equipment)) {
            selectEquipment(equipment);
            (async () => await loadPatients(equipment))();
          }else{
            selectEquipment(equipments[0].id_tag);
            (async () => await loadPatients(equipments[0].id_clinica))();
          }
        }
      }
      document.getElementById("app").style.cursor = "default"; //volta para o cursor normal após terminar de carregar os dados
    };

    (async () => await loadData())(); //carrega os dados de forma assincrona
  }, []);

  return (
    <App>
      <Page>
        <PageHead>
          <PageTitle>Pacientes</PageTitle>
          <PageHeadBar>
            <PageHeadSelect onChange={e => loadPatients(e.target.value)} value={equipment}> {/* Select dos equipamentos */}
              {equipmentList.map((equip) => (
                <PageHeadOption value={equip.id_tag} key={equip.id_tag} >{equip.nome_equipamento}</PageHeadOption>
              ))}
            </PageHeadSelect>
            <SearchPatient  // campo de busca do paciente
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
            />
          </PageHeadBar>
        </PageHead>
        <PageList>
          <ListHeader>
            <PatientListItemText>
              <PatientListItemTextFirst>Nome</PatientListItemTextFirst>
              <PatientListItemTextInfo>Prontuário</PatientListItemTextInfo>
              <PatientListItemTextInfo>Data Nasc.</PatientListItemTextInfo>
            </PatientListItemText>
          </ListHeader>
          {patientList //filtra e ordena a lista depois gera cada item através do map
            .filter((patient) => {
              if (filter === "name") {
                return patient.nome.toLowerCase().includes(search.toLowerCase());
              } else {
                return patient.pront.toLowerCase().includes(search.toLowerCase());
              }
            })
            .sort((a, b) => {
              if (filter === "name") {
                return sort === "Asc" ? a.nome.toLowerCase().localeCompare(b.nome.toLowerCase()) : b.nome.toLowerCase().localeCompare(a.nome.toLowerCase());
              } else {
                return sort === "Asc" ? a.pront.toLowerCase().localeCompare(b.pront.toLowerCase()) : b.pront.toLowerCase().localeCompare(a.pront.toLowerCase());
              }
            }
            )
            .map((patient) => (
              <Patient
                key={patient.id}
                patient={patient}
              />
            ))
          }
        </PageList>
      </Page>
    </App>
  )
};

export default PatientPage;