//requisições para o servidor utilizando axios

import axios from "axios";

export const api = axios.create({
    //diferença do servidor se está em desenvolvimento ou produção
    baseURL: process.env.REACT_APP_STATUS === "prod" ? process.env.REACT_APP_AXIOS_BASE_URL_PROD : process.env.REACT_APP_AXIOS_BASE_URL_DEV,
    maxBodyLength: Infinity,
});

// SESSION
export const createSession = async (user, password) => {
    let response = await api.post('/login', { user: user, password: password }) //requisições post precisa da rota da requisição e do json a ser enviado
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

// USER
export const getUsersClinic = async (id) => {
    let response = await api.get(`/user/clinic?id_clinic=${id}`) //requisições get precisa da rota, após o "?"" pode ser adiconadas informações que serão enviadas
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const deleteUserForId = async (id) => {
    let response = await api.delete(`/user?id=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const putUser = async (id, name, login, email) => {
    let data = {
        "id": id,
        "name": name,
        "login": login,
        "email": email,
    };
    
    let response = await api.put(`/user`, data)
        .then((res) => {
            return res.status;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

// EQUIPMENT
export const getEquipmentsClinic = async (id) => {
    let response = await api.get(`/equipment/clinic?id_clinic=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const deleteEquipmentForId = async (id) => {
    let response = await api.delete(`/equipment?id=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const putEquipment = async (id, name, serial, date, id_clinic) => {
    let data = {
        "id": id,
        "name": name,
        "serialNumber": serial,
        "date": date,
        "id_clinic": id_clinic
    };
    
    let response = await api.put(`/equipment`, data)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

// PATIENT
export const getAllPatients = async (id_tag) => {
    let response = await api.get(`/patient/?tag=${id_tag}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const deletePatientForId = async (id) => {
    let response = await api.delete(`/patient?id=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

// EXAM
export const getAllExams = async (id_tag, patient_codigo) => {
    let response = await api.get(`/patient/exam?tag=${id_tag}&codigo=${patient_codigo}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const getExamImg = async (id, id_tag) => {
    let response = await api.get(`/patient/exam/img/?id=${id}&tag=${id_tag}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const deleteExamForId = async (id) => {
    let response = await api.delete(`/patient/exam/?id=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

// CLINIC
export const getClinic = async (id) => {
    let response = await api.get(`/clinic?id=${id}`)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};

export const putClinic = async (id, name, address, email, telephone, isWhatsApp) => {
    let data = {
        "id": id,
        "name": name,
        "address": address,
        "email": email,
        "telephone": telephone,
        "isWhatsApp": isWhatsApp
    };
    
    let response = await api.put(`/clinic`, data)
        .then((res) => {
            return res.data;
        })
        .catch(function (error) {
            if (error.response) {
                return (error.response.status);
            }
        });
    return response;
};