import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './contexts/auth';
import LoginPage from './pages/LoginPage.js';
import HomePage from './pages/HomePage.js';
import PacientPage from './pages/PatientPage.js';
import ExamPage from './pages/ExamPage.js';
import ClinicPage from './pages/ClinicPage.js';

// falta fazer página de "home page" para a rota "/", por enquanto está usando a página
// falta fazer página de "not found 404" para a rota "*", por enquanto está redirecionando para rota inicial "/"

const AppRoutes = () => {
    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return (
                <div className='loading-route'>Carregando...</div>
            )
        }

        if (!authenticated) { // caso tente acessar alguma rota privada sem estar autenticado será redirecionado para a rota de login
            return (
                <Navigate to='/login' />
            )
        }
        return children;
    }

    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path='/' element={<LoginPage />} />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/patient' element={<Private><PacientPage /></Private>} /> {/*para rotas dentro do "private" precisa estar autenticado*/}
                    <Route path='/patient/exam' element={<Private><ExamPage /></Private>} />
                    <Route path='/clinic' element={<Private><ClinicPage /></Private>} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default AppRoutes;