import { styled } from 'styled-components';
import bgImage from "../images/cloud-bg2.png"

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 720px;
  max-height: 100vh;
  background-color: #e7e7e7;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const PageHead = styled.div`
  background-image: url(${bgImage});
	background-repeat: no-repeat;
  /*background-attachment: fixed;*/
  background-size: 100% 100%;
	color: #1f3a72;
`;

export const PageTitle = styled.h1`
  padding: 10px 0 0 20px;
`;

export const PageHeadBar = styled.div`
  border-bottom: 1px solid #555555;
  display: grid;
  grid-template-columns: 250px auto;
  justify-content: space-around;
  align-items: end;
`;

export const PageHeadSelect = styled.select`
  background-color: rgba(0,64,158, 0.7);
  color: rgba(255,255,255, 1);;
  width: 150px;
  height: 36px;
  border-radius: 10px 10px 0 0;
`;

export const PageHeadOption = styled.option`
  background-color: #FFFFFF;
  color: rgba(0,64,158, 1);
  text-align: center;
`;

export const PageList = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 10px;

  @media screen and (min-width: 481px) and (max-width: 1219px) {
    grid-template-rows: 35px repeat(auto-fill, 65px);
  }

  @media screen and (min-width: 1220px) and (max-width: 9999px) {
    grid-template-rows: 35px repeat(auto-fill, 35px);
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
`;

export const ListHeader = styled.div`
  display: grid;
  height: 30px;
  width: 80vw;
  min-width: 700px;
  border-bottom: 1px solid #1f3a72;
  color: #1f3a72;
`;

export const ListItem = styled.div`
  display: grid;
  width: 80vw;
  min-width: 700px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  margin-bottom: 5px;

  @media screen and (min-width: 481px) and (max-width: 1219px) {
    height: 60px;
  }

  @media screen and (min-width: 1220px) and (max-width: 9999px) {
    height: 30px;
  }
`;

export const ListShortHeader = styled.div`
  display: grid;
  height: 30px;
  width: 40vw;
  min-width: 700px;
  border-bottom: 1px solid #1f3a72;
  color: #1f3a72;
`;

export const ListShortItem = styled.div`
  display: grid;
  width: 40vw;
  min-width: 700px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  margin-bottom: 5px;

  @media screen and (min-width: 481px) and (max-width: 1219px) {
    height: 60px;
  }

  @media screen and (min-width: 1220px) and (max-width: 9999px) {
    height: 30px;
  }
`;

export const ListItemLink = styled.div`
  display: grid;
	grid-template-columns: 100%;
	align-items: center;
	justify-content: center;
`;

export const ListItemText = styled.p`
  display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
`;