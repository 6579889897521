import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

const ModalTitle = styled.p`
    font-size: 25px;
    color: #FFFFFF;
    //color: #1f3a72;
`;

const ModalAlertBox = styled.div`
    width: 550px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #999999;
    border: 1px solid #FFFFFF;
`;

const ModalAlertBoxButtons = styled.div`
    width: 60%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const ModalAlertButton = styled.button`
    width: 40%;
    height: 100%;
    display: grid;
    color: #FFFFFF;
    background-color: rgba(0,64,158,1);
    align-items: center;
`;

const PageContent = styled.div`
    width: 500px;
    height: 180px;
    display: grid;
    grid-template-rows: repeat(auto-fill, 60px);
    grid-template-columns: 30% 70%;
    justify-content: center;
    margin-top: 10px;
`;

const ContentLabel = styled.label`
    color: #FFFFFF;
    font-size: 25px;
`;

const ContentInputText = styled.input.attrs({ type: 'text' })`
    width: 300px;
    height: 30px;
    font-size: 20px;
`;

export const ModalEditEquipment = ({ showModal, setShowModal, save, name, setName, nbSerie, setNbSerie }) => {
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
        position: "absolute",
        top: "100px",
        right: "calc(50vw - 200px)"
    });

    const closeModal = e => {
        setShowModal(false);
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(() => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <>
            {showModal ? (
                <animated.div style={animation}>
                    <ModalAlertBox>
                        <ModalTitle>Editar Equipamento</ModalTitle>
                        <PageContent>
                            <ContentLabel>Nome:</ContentLabel>
                            <ContentInputText value={name} onChange={e => setName(e.target.value)} />
                            <ContentLabel>Nr. Série:</ContentLabel>
                            <ContentInputText value={nbSerie} onChange={e => setNbSerie(e.target.value)} />
                        </PageContent>
                        <ModalAlertBoxButtons>
                            <ModalAlertButton onClick={save}>Salvar</ModalAlertButton>
                            <ModalAlertButton onClick={closeModal}>Cancelar</ModalAlertButton>
                        </ModalAlertBoxButtons>
                    </ModalAlertBox>
                </animated.div>
            ) : null}
        </>
    );
};


export const ModalEditUser = ({ showModal, setShowModal, save, name, setName, login, setLogin, email, setEmail }) => {
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
        position: "absolute",
        top: "100px",
        right: "calc(50vw - 200px)"
    });

    const closeModal = e => {
        setShowModal(false);
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(() => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <>
            {showModal ? (
                <animated.div style={animation}>
                    <ModalAlertBox>
                        <ModalTitle>Editar Equipamento</ModalTitle>
                        <PageContent>
                            <ContentLabel>Nome:</ContentLabel>
                            <ContentInputText value={name} onChange={e => setName(e.target.value)} />
                            <ContentLabel>Login:</ContentLabel>
                            <ContentInputText value={login} onChange={e => setLogin(e.target.value)} />
                            <ContentLabel>E-mail:</ContentLabel>
                            <ContentInputText value={email} onChange={e => setEmail(e.target.value)} />
                        </PageContent>
                        <ModalAlertBoxButtons>
                            <ModalAlertButton onClick={save}>Salvar</ModalAlertButton>
                            <ModalAlertButton onClick={closeModal}>Cancelar</ModalAlertButton>
                        </ModalAlertBoxButtons>
                    </ModalAlertBox>
                </animated.div>
            ) : null}
        </>
    );
};