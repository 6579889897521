import React, { useContext } from 'react'
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/auth';
import { ListItem, ListItemLink, ListItemText } from './Common.js';
import {PatientListItemText} from '../pages/PatientPage.js'

const PatientListItemTextFirst = styled.p`
  display: flex;
	align-items: center;
	justify-content: left;
	font-size: 18px;
  padding-left: 30px;
`;

const ListItemPatient = styled(ListItem)`
  & :hover {
  border-radius: 10px;
  color: white;
  background-color: rgba(0,64,158,1);
  cursor: pointer;
  }  
`;

const Patient = ({ patient }) => {
  const { reloadStates } = useContext(AuthContext);

  const navigate = useNavigate();

  const redirectTo = () => {
    localStorage.setItem('patient', JSON.stringify(patient));
    reloadStates();
    navigate("/patient/exam");
  }

  const data = patient.data.slice(8, 10) + patient.data.slice(4, 8) + patient.data.slice(0, 4);
  
  return (
    <ListItemPatient>
      <ListItemLink onClick={redirectTo}>
        <PatientListItemText>
          <PatientListItemTextFirst>{patient.nome}</PatientListItemTextFirst>
          <ListItemText>{patient.pront}</ListItemText>
          <ListItemText>{data}</ListItemText>
        </PatientListItemText>
      </ListItemLink>
    </ListItemPatient>
  )
};

export default Patient;