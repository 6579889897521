import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import App from '../components/App.js';
import { getEquipmentsClinic, getClinic, putClinic, getUsersClinic } from '../services/Api.js';
import { Page, PageContent, PageHead, PageTitle, PageList, ListShortHeader } from '../components/Common.js';
import Equipment from '../components/Equipment.js';
import User from '../components/User';

const PageContentClinic = styled.div`
    width: 700px;
    height: 240px;
    display: grid;
    grid-template-rows: repeat(auto-fill, 60px);
    grid-template-columns: 20% 80%;
    justify-content: center;
    margin: 10px 0 50px 0;
`;

const ContentLine = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
`;

const ContentGroup = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContentLabel = styled.label`
    color: #1f3a72;
    font-size: 25px;
`;

const ContentInputText = styled.input.attrs({ type: 'text' })`
    width: 560px;
    height: 30px;
    font-size: 20px;
`;

const ContentInputTel = styled.input.attrs({ type: 'tel' })`
    width: 152px;
    height: 30px;
    font-size: 20px;
`;

const ContentInputCheck = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 5px;
`;

const ActionLine = styled.div`
    display: flex;
    justify-content: space-around;
`;

const ActionButton = styled.button`
    width: 120px;
    height: 50px;
    padding: 0;
    margin: 0 125px;
    background: #1f3a72;
    border: 2px solid #0064a7;
    border-radius: 5px;
    color: #FDFDFD;
    font-size: 25px;
`;

export const EquipmentListItemText = styled.div`
    display: grid;
    grid-template-columns: minmax(250px, 50%) minmax(150px, 40%) minmax(100px, 10%);
    align-items: center;
    justify-content: space-between;
`;

const EquipmentListItemTextFirst = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    padding-left: 30px;
`;

const EquipmentListItemTextInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
`;

const PageTwoLists = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
    justify-content: center;
    border-top: 1px solid black;
    margin-top: 15px;
`;

export const UserListItemText = styled.div`
    display: grid;
    grid-template-columns: minmax(175px, 35%) minmax(100px, 20%) minmax(175px, 35%) minmax(100px, 10%);
    align-items: center;
    justify-content: space-between;
`;

const TitleList = styled.h2`
    font-size: 30px;
    text-align: center;
    margin-top: 10px;
`;


const ClinicPage = () => {
    const { user, logout } = useContext(AuthContext);

    const [clinic, setClinic] = useState([]);

    const [name, setName] = useState("");

    const [address, setAddress] = useState("");

    const [email, setEmail] = useState("");

    const [telephone, setTelephone] = useState("");

    const [isWhatsApp, setIsWhatsApp] = useState(false);

    const [equipmentList, setEquipmentList] = useState([]);

    const [userList, setUserList] = useState([]);

    const navigate = useNavigate();

    const resetValues = () => {
        setName(clinic.nome);
        setAddress(clinic.endereco);
        setEmail(clinic.email);
        setTelephone(clinic.telefone);
        setIsWhatsApp(clinic.isWhatsApp);
    };

    const updateClinic = async () => {
        let res = await putClinic(clinic.id, name, address, email, telephone, isWhatsApp);
        if (res === 401) {
            logout();
            navigate("/login");
        }
    };

    const phoneMask = (value) => {
        if (!value) return "";
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, "($1) $2");
        value = value.replace(/(\d)(\d{4})$/, "$1-$2");
        return value;
    };

    const loadData = async () => {
        document.getElementById("app").style.cursor = "wait";
        if (user) {
            let clinics = await getClinic(user.clinic);
            if (clinics === 401) {
                logout();
                navigate("/login");
            } else {
                setName(clinics[0].nome);
                setAddress(clinics[0].endereco);
                setEmail(clinics[0].email);
                setTelephone(clinics[0].telefone);
                setIsWhatsApp(clinics[0].isWhatsApp);
                setClinic(clinics[0]);


                let users = await getUsersClinic(user.clinic);
                if (users === 401) {
                    logout();
                    navigate("/login");
                } else {
                    setUserList(users);

                    let equipments = await getEquipmentsClinic(user.clinic);
                    if (equipments === 401) {
                        logout();
                        navigate("/login");
                    } else {
                        setEquipmentList(equipments);
                    }
                }
            }
        }

        document.getElementById("app").style.cursor = "default";
    };

    useEffect(() => {
        (async () => await loadData())();
    }, []);

    return (
        <App>
            <Page>
                <PageHead>
                    <PageTitle>Clínica</PageTitle>
                    <div></div>
                </PageHead>
                <PageContent>
                    <PageContentClinic>
                        <ContentLabel>Nome:</ContentLabel>
                        <ContentInputText value={name} onChange={e => setName(e.target.value)} />
                        <ContentLabel>Endereço:</ContentLabel>
                        <ContentInputText value={address} onChange={e => setAddress(e.target.value)} />
                        <ContentLabel>E-mail:</ContentLabel>
                        <ContentInputText value={email} onChange={e => setEmail(e.target.value)} />
                        <ContentLabel>Telefone:</ContentLabel>
                        <ContentGroup>
                            <ContentInputTel value={phoneMask(telephone)} onChange={e => setTelephone(phoneMask(e.target.value))} placeholder='(00) 00000-0000' />
                            <ContentLabel>WhatsApp:</ContentLabel>
                            <ContentLabel><ContentInputCheck checked={isWhatsApp ? true : false} name='whatsapp' value={true} onChange={() => setIsWhatsApp(true)} />Sim</ContentLabel>
                            <ContentLabel><ContentInputCheck checked={isWhatsApp ? false : true} name='whatsapp' value={false} onChange={() => setIsWhatsApp(false)} />Não</ContentLabel>
                        </ContentGroup>
                    </PageContentClinic>
                    <ActionLine>
                        <ActionButton onClick={updateClinic}>Salvar</ActionButton>
                        <ActionButton onClick={resetValues}>Cancelar</ActionButton>
                    </ActionLine>
                </PageContent>
                <PageTwoLists>
                    <TitleList>Usuários:</TitleList>
                    <TitleList>Equipamentos:</TitleList>
                    <PageList>
                        <ListShortHeader>
                            <UserListItemText>
                                <EquipmentListItemTextFirst>Nome</EquipmentListItemTextFirst>
                                <EquipmentListItemTextInfo>Login</EquipmentListItemTextInfo>
                                <EquipmentListItemTextInfo>E-mail</EquipmentListItemTextInfo>
                                <EquipmentListItemTextInfo>Opções</EquipmentListItemTextInfo>
                            </UserListItemText>
                        </ListShortHeader>
                        {userList
                            .sort((a, b) => {
                                return a.nome.toLowerCase().localeCompare(b.nome.toLowerCase());
                            })
                            .map((user) => (
                                <User
                                    key={user.id}
                                    user={user}
                                    reloadList={loadData}
                                />
                            ))
                        }
                    </PageList>
                    <PageList>
                        <ListShortHeader>
                            <EquipmentListItemText>
                                <EquipmentListItemTextFirst>Nome</EquipmentListItemTextFirst>
                                <EquipmentListItemTextInfo>Número Série</EquipmentListItemTextInfo>
                                <EquipmentListItemTextInfo>Opções</EquipmentListItemTextInfo>
                            </EquipmentListItemText>
                        </ListShortHeader>
                        {equipmentList
                            .sort((a, b) => {
                                return a.nome_equipamento.toLowerCase().localeCompare(b.nome_equipamento.toLowerCase());
                            })
                            .map((equipment) => (
                                <Equipment
                                    key={equipment.id_tag}
                                    equipment={equipment}
                                    reloadList={loadData}
                                />
                            ))
                        }
                    </PageList>
                </PageTwoLists>
            </Page>
        </App>
    )
};

export default ClinicPage;