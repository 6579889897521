import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import { deleteUserForId, putUser } from '../services/Api.js';
import { ListItemLink, ListItemText, ListShortItem } from './Common.js';
import { UserListItemText } from '../pages/ClinicPage.js';
import editIcon from "../images/editar.png";
import deleteIcon from "../images/excluir.png";
import { ModalAlert } from './Modal.js';
import { ModalEditUser } from './ModalEdit.js';
import Tooltip from './Tooltip';

const ListItemTextFirst = styled.p`
    display: flex;
    align-items: center;
	justify-content: left;
	font-size: 18px;
    padding-left: 30px;
`;

const ListItemButtons = styled.div`
    display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 18px;

    & :hover {
        cursor: pointer;
    } 
`;

const ListItemIcon = styled.img`
    height: 20px;
    width: 20px;
`;

const User = ({ user, reloadList }) => {
    const { logout } = useContext(AuthContext);

    const [modalAlert, setModalAlert] = useState(false);

    const [modalEdit, setModalEdit] = useState(false);

    const [deleteText, setDeleteText] = useState("");

    const [name, setName] = useState("");

    const [login, setLogin] = useState("");

    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    const deleteUser = async () => {
        let res = await deleteUserForId(user.id);
        if (res === 401) {
            logout();
            navigate("/login");
        } else {
            if(res === 409){

            }else{
                reloadList();
            }
        }
    };

    const updateUser = async () => {
        let res = await putUser(user.id, name, login, email);
        if (res === 401) {
            logout();
            navigate("/login");
        } else {
            reloadList();
        }
        setModalEdit(false);
    };

    const openAlert = () => {
        setDeleteText("Deseja excluir o usuário?");
        setModalAlert(true);
    };

    const openEdit = () => {
        setName(user.nome);
        setLogin(user.login);
        setEmail(user.email);
        setModalEdit(true);
    };

    return (
        <ListShortItem>
            <ListItemLink >
                <UserListItemText>
                    <ListItemTextFirst>{user.nome}</ListItemTextFirst>
                    <ListItemText>{user.login}</ListItemText>
                    <ListItemText>{user.email}</ListItemText>
                    <ListItemButtons>
                        <Tooltip text={"editar"}><ListItemIcon onClick={() => openEdit()} src={editIcon} alt='editar' aria-label='editar'/></Tooltip>
                        <Tooltip text={"excluir"}><ListItemIcon onClick={() => openAlert()} src={deleteIcon} alt='excluir' aria-label='excluir'/></Tooltip>
                    </ListItemButtons>
                </UserListItemText>
            </ListItemLink>
            <ModalAlert
                showModal={modalAlert}
                setShowModal={setModalAlert}
                confirm={deleteUser}
                confirmText={"Excluir"}
                text={deleteText}
            ></ModalAlert>
            <ModalEditUser
                showModal={modalEdit}
                setShowModal={setModalEdit}
                save={updateUser}
                name={name}
                setName={setName}
                login={login}
                setLogin={setLogin}
                email={email}
                setEmail={setEmail}
            ></ModalEditUser>
        </ListShortItem>
    )
};

export default User;