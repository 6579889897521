import React, { useContext, useState } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import { putEquipment, deleteEquipmentForId } from '../services/Api.js';
import { ListItemLink, ListItemText, ListShortItem } from './Common.js';
import { EquipmentListItemText } from '../pages/ClinicPage.js';
import editIcon from "../images/editar.png";
import deleteIcon from "../images/excluir.png";
import { ModalAlert } from './Modal.js';
import { ModalEditEquipment } from './ModalEdit.js';
import Tooltip from './Tooltip';

const ListItemTextFirst = styled.p`
    display: flex;
    align-items: center;
	justify-content: left;
	font-size: 18px;
    padding-left: 30px;
`;

const ListItemButtons = styled.div`
    display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 18px;

    & :hover {
        cursor: pointer;
    } 
`;

const ListItemIcon = styled.img`
    height: 20px;
    width: 20px;
`;

const Equipment = ({ equipment, reloadList }) => {
    const { logout } = useContext(AuthContext);

    const [modalAlert, setModalAlert] = useState(false);

    const [modalEdit, setModalEdit] = useState(false);

    const [deleteText, setDeleteText] = useState("");

    const [name, setName] = useState("");

    const [nbSerie, setNbSerie] = useState("");

    const navigate = useNavigate();

    const deleteEquipment = async () => {
        let res = await deleteEquipmentForId(equipment.id_tag);
        if (res === 401) {
            logout();
            navigate("/login");
        } else {
            reloadList();
        }
    };

    const updateEquipment = async () => {
        let res = await putEquipment(equipment.id_tag, name, nbSerie, equipment.data_cadastro, equipment.id_clinica);
        if (res === 401) {
            logout();
            navigate("/login");
        } else {
            reloadList();
        }
    };

    const openAlert = () => {
        setDeleteText("Deseja excluir o equipamento? (todos os pacientes e seus exames também serão excluídos)");
        setModalAlert(true);
    };

    const openEdit = () => {
        setName(equipment.nome_equipamento);
        setNbSerie(equipment.nro_serie);
        setModalEdit(true);
    };

    return (
        <ListShortItem>
            <ListItemLink >
                <EquipmentListItemText>
                    <ListItemTextFirst>{equipment.nome_equipamento}</ListItemTextFirst>
                    <ListItemText>{equipment.nro_serie}</ListItemText>
                    <ListItemButtons>
                        <Tooltip text={"editar"}><ListItemIcon onClick={() => openEdit()} src={editIcon} alt='editar' aria-label='editar'/></Tooltip>
                        <Tooltip text={"excluir"}><ListItemIcon onClick={() => openAlert()} src={deleteIcon} alt='excluir' aria-label='excluir'/></Tooltip>
                    </ListItemButtons>
                </EquipmentListItemText>
            </ListItemLink>
            <ModalAlert
                showModal={modalAlert}
                setShowModal={setModalAlert}
                confirm={deleteEquipment}
                confirmText={"Excluir"}
                text={deleteText}
            ></ModalAlert>
            <ModalEditEquipment
                showModal={modalEdit}
                setShowModal={setModalEdit}
                save={updateEquipment}
                name={name}
                setName={setName}
                nbSerie={nbSerie}
                setNbSerie={setNbSerie}
            ></ModalEditEquipment>
        </ListShortItem>
    )
};

export default Equipment;