import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Exam from '../components/Exam.js';
import { getAllExams } from '../services/Api.js';
import { AuthContext } from '../contexts/auth';
import App from '../components/App.js';
import { SearchExam } from '../components/Search.js';
import { Page, PageHead, PageTitle, PageList, ListHeader } from '../components/Common.js';
import { ModalExam } from '../components/Modal.js';

export const ExamListItemText = styled.div`
  display: grid;
	grid-template-columns: minmax(100px, 20%) minmax(100px, 20%) minmax(100px, 20%) minmax(100px, 20%) minmax(100px, 20%);
  align-items: center;
  justify-content: space-between;
`;

const ExamListItemTextFirst = styled.div`
  display: flex;
	align-items: center;
	justify-content: left;
	font-size: 18px;
  padding-left: 30px;
`;

const ExamListItemTextInfo = styled.div`
  display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
`;

const ExamPage = () => {
  const { patient, logout, equipment } = useContext(AuthContext);

  const [examList, setExamList] = useState([]);

  const [search, setSearch] = useState("");

  const [filter, setFilter] = useState("exam");

  const [sort, setSort] = useState("Asc");

  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [modalImg, setModalImg] = useState("");

  const [modalImgTitle, setModalImgTitle] = useState("");

  const navigate = useNavigate();

  const openModal = () => {
    setShowModal(true)
  };

  const loadData = async () => {
    document.getElementById("app").style.cursor = "wait";
    let res = await getAllExams(equipment, patient?.codigo);
    if (res === 401) {
      logout();
      navigate("/login");
    } else {
      setExamList(res);
      setLoading(false);
    }
    document.getElementById("app").style.cursor = "default";
  };

  useEffect(() => {
    (async () => await loadData())();
  }, []);

  return (
    <App>
      <Page>
        <PageHead>
          <PageTitle>Exames | {patient?.nome}</PageTitle>
          <SearchExam
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
          />
        </PageHead>
        <PageList>
          <ListHeader>
            <ExamListItemText>
              <ExamListItemTextFirst>Exame</ExamListItemTextFirst>
              <ExamListItemTextInfo>Lado</ExamListItemTextInfo>
              <ExamListItemTextInfo>Data / Hora</ExamListItemTextInfo>
              <ExamListItemTextInfo>Médico</ExamListItemTextInfo>
              <ExamListItemTextInfo>Opções</ExamListItemTextInfo>
            </ExamListItemText>
          </ListHeader>
          {examList
            .filter((exam) => {
              if (filter === "exam") {
                return exam.tipo_exame.toLowerCase().includes(search.toLowerCase());
              } else {
                return exam.data_hora.toLowerCase().includes(search.toLowerCase());
              }
            })
            .sort((a, b) => {
              if (filter === "exam") {
                return sort === "Asc" ? a.tipo_exame.toLowerCase().localeCompare(b.tipo_exame.toLowerCase()) : b.tipo_exame.toLowerCase().localeCompare(a.tipo_exame.toLowerCase());
              } else {
                return sort === "Asc" ? b.data_hora.toLowerCase().localeCompare(a.data_hora.toLowerCase()) : a.data_hora.toLowerCase().localeCompare(b.data_hora.toLowerCase());
              }
            })
            .map((exam) => (
              <Exam
                key={exam.id}
                exam={exam}
                openModal={openModal}
                modalImg={setModalImg}
                imgTitle={setModalImgTitle}
                reloadList={loadData}
                examLength={examList.length}
                patientId={patient?.id}
                patientName={patient?.nome}
              />
            ))
          }
        </PageList>
        <ModalExam
          showModal={showModal}
          setShowModal={setShowModal}
          patientName={patient?.nome}
          examImg={modalImg}
          examTitle={modalImgTitle}
        ></ModalExam>
      </Page>
    </App>
  )
};

export default ExamPage;