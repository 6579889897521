import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { AuthContext } from '../contexts/auth';
import SideBar from './SideBar.js';

const AppComponent = styled.div`
    min-width: 100vw;
    margin: 0;
    background-color: #efefef;
    display: grid;
    grid-template-columns: ${props => JSON.parse(props.$expand) ? "240px" : "62px"} minmax(720px, auto);
    overflow: hidden;
`;

function App({ children }) {
    const { expand, loading } = useContext(AuthContext);
    
    if (loading) {
        return (
            <div className='loading-route'>Carregando...</div>
        )
    }

    return (
        <AppComponent  $expand={expand} id='app'>
            <SideBar/>
            { children }
        </AppComponent>
    )
}

export default App