import React, { useState, useContext, useEffect } from 'react';
import './LoginPage.css'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';

const LoginPage = () => {
  const { authenticated, login } = useContext(AuthContext);
  const [userLogin, setUserLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userLogin || !password) {
      setErrorMessage("Preencha todos os campos");
      return;
    } else {
      const authenticated = await login(userLogin, password);
      if (authenticated) {
        setErrorMessage("");
        navigate("/patient");
      } else {
        setErrorMessage("Usúario e/ou senha inválidos.");
      }
    }
  };

  useEffect(() => {
    if (authenticated) {
      setTimeout(() => {
        navigate("/patient");
      }, "3000");
    }
  });

  return (
    <div className='app'>
      <div id='login-page' className='login-page'>
        <h1 className='login-page-title'>Login</h1>
        <div className="login">
          <form className='login-form' onSubmit={handleSubmit}>
            <p className='login-form-message'>{errorMessage}</p>
            <label htmlFor='user-login' className='login-form-user'>Usuário:</label>
            <input
              id='user-login'
              type='text'
              placeholder='usuário'
              name='user-login'
              onChange={(e) => setUserLogin(e.target.value)}
              value={userLogin}
            />
            <label htmlFor='password-login' className='login-form-password'>Senha:</label>
            <input
              id='password-login'
              type='password'
              placeholder='senha'
              name='password-login'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button type='submit' className='login-form-button'>Entrar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;