import React from 'react';
import { styled } from 'styled-components';

const SearchDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const SearchFilterOptions = styled.div`
  margin-bottom: 5px;
  @media screen and (max-width: 1255px) {
      display: grid;
      grid-template-rows: 33% 33% 33%;
  }

  @media screen and (min-width: 1255px) and (max-width: 9999px) {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
  }
`;

const FilterInputText = styled.input`
  padding: 5px;
  margin: 0 10px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  width: 250px;
  height: 30px;
`;

const FilterInputRadio = styled.input`
  margin: ${props => JSON.parse(props.$first) ? "0 5px 0 0" : "0 5px 0 20px"};
  position: relative;

  &:checked:after {
    background-color: #333974;
  }

  &:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    cursor: pointer;
  }

  @media screen and (max-width: 1255px) {
    
    &:after {
      left: -1px;
    }
  }

  @media screen and (min-width: 1255px) and (max-width: 9999px) {
    top: -1px;
    &:after {
      left: -1px;
    }
  }
`;

const FilterItem = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const FilterItemText = styled.p`
  display: inline-block;
  padding: 0 5px;
`;

const FilterItemTitle = styled.h2`
  display: inline-block;
`;

const FilterItemLabel = styled.label`
  position: relative;
  top: 1px;
`;

export const SearchPatient = ({ search, setSearch, filter, setFilter, sort, setSort }) => {
  return (
    <SearchDiv>
      <SearchFilterOptions>
        <FilterItem>
          <FilterItemTitle>Pesquisar:</FilterItemTitle>
          <FilterInputText type='text' name='search-text' placeholder={filter === "name" ? 'Digite o nome do paciente...' : 'Digite o prontuário do paciente...'} value={search} onChange={(e) => setSearch(e.target.value)} />
        </FilterItem>
        <FilterItem>
          <FilterItemText>Buscar por:</FilterItemText>
          <FilterInputRadio id='search-name'  $first={'true'} type='radio' value="name" name='search-fiter' checked={filter === "name"} onChange={(e) => setFilter(e.target.value)} />
          <FilterItemLabel htmlFor='search-name'>Nome</FilterItemLabel>
          <FilterInputRadio id='search-record'  $first={'false'} type='radio' value="record" name='search-filter' checked={filter === "record"} onChange={(e) => setFilter(e.target.value)} />
          <FilterItemLabel htmlFor='search-record' >Prontuário</FilterItemLabel>
        </FilterItem>
        <FilterItem>
          <FilterItemText>Ordem:</FilterItemText>
          <FilterInputRadio id='search-asc'  $first={'true'} type='radio' value="Asc" name='sort' checked={sort === "Asc"} onChange={(e) => setSort(e.target.value)} />
          <FilterItemLabel htmlFor='search-asc'>{"Crescente (A...Z)"}</FilterItemLabel>
          <FilterInputRadio id='search-desc'  $first={'false'} type='radio' value="Desc" name='sort' checked={sort === "Desc"} onChange={(e) => setSort(e.target.value)} />
          <FilterItemLabel htmlFor='search-desc'>{"Decrescente (Z...A)"}</FilterItemLabel>
        </FilterItem>
      </SearchFilterOptions>
    </SearchDiv>
  )
};

export const SearchExam = ({ search, setSearch, filter, setFilter, sort, setSort }) => {
  return (
    <SearchDiv>
      <SearchFilterOptions>
        <FilterItem>
          <FilterItemTitle>Pesquisar:</FilterItemTitle>
          <FilterInputText type='text' name='search-text' placeholder={filter === "exam" ? 'Digite o nome do exame...' : 'Digite a data do exame...'} value={search} onChange={(e) => setSearch(e.target.value)} />
        </FilterItem>
        <FilterItem>
          <FilterItemText>Buscar por:</FilterItemText>
          <FilterInputRadio id='search-exam'  $first={'true'} type='radio' value="exam" name='search-fiter' checked={filter === "exam"} onChange={(e) => setFilter(e.target.value)} />
          <FilterItemLabel htmlFor='search-exam'>Exame</FilterItemLabel>
          <FilterInputRadio id='search-record'  $first={'false'} type='radio' value="record" name='search-filter' checked={filter === "record"} onChange={(e) => setFilter(e.target.value)} />
          <FilterItemLabel htmlFor='search-record'>Data</FilterItemLabel>
        </FilterItem>
        <FilterItem>
          <FilterItemText>Ordem:</FilterItemText>
          <FilterInputRadio id='search-asc'  $first={'true'} type='radio' value="Asc" name='sort' checked={sort === "Asc"} onChange={(e) => setSort(e.target.value)} />
          <FilterItemLabel htmlFor='search-asc'>{"Crescente (A...Z)"}</FilterItemLabel>
          <FilterInputRadio id='search-desc'  $first={'false'} type='radio' value="Desc" name='sort' checked={sort === "Desc"} onChange={(e) => setSort(e.target.value)} />
          <FilterItemLabel htmlFor='search-desc'>{"Decrescente (Z...A)"}</FilterItemLabel>
        </FilterItem>
      </SearchFilterOptions>
    </SearchDiv>
  )
};