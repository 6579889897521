import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import closeIcon from '../images/close-blue.png'

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalWrapper = styled.div`
    @media screen and (min-width: 481px) and (max-width: 768px) {
        width: 50vw;
        min-width: 400px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 769px) and (max-width: 9999px) {
        width: 50vw;
        min-width: 600px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #000;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;
`;

const ModalTitle = styled.p`
    font-size: 20px;
    color: #FFFFFF;
`;

const CloseModalButton = styled.img`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
    z-index: 10;
`;

const ModalAlertBox = styled.div`
    width: 400px;
    height: 200px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 80% 20%;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #999999;
    border: 1px solid #FFFFFF;
`;

const ModalAlertBoxButtons = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const ModalAlertButton = styled.button`
    width: 100%;
    height: 100%;
    display: grid;
    color: #FFFFFF;
    background-color: rgba(0,64,158,1);
`;

export const ModalExam = ({ showModal, setShowModal, patientName, examImg, examTitle }) => {
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <>
            {showModal ? (
                <Background onClick={closeModal} ref={modalRef}>
                    <animated.div style={animation}>
                        <ModalWrapper>
                            <ModalImg src={examImg} alt='exame' />
                            <ModalContent>
                                <ModalTitle>{patientName}: {examTitle}</ModalTitle>
                            </ModalContent>
                            <CloseModalButton
                                aria-label='Close modal'
                                onClick={() => setShowModal(prev => !prev)}
                                src={closeIcon}
                            />
                        </ModalWrapper>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
};

export const ModalAlert = ({ showModal, setShowModal, confirm, confirmText, text }) => {
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
        position: "absolute",
        top: "100px",
        right: "calc(50vw - 200px)"
    });

    const closeModal = e => {
        setShowModal(false);
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <>
            {showModal ? (
                <animated.div style={animation}>
                    <ModalAlertBox>
                        <ModalTitle>{text}</ModalTitle>
                        <ModalAlertBoxButtons>
                            <ModalAlertButton onClick={confirm}>{confirmText}</ModalAlertButton>
                            <ModalAlertButton onClick={closeModal}>Cancelar</ModalAlertButton>
                        </ModalAlertBoxButtons>
                    </ModalAlertBox>
                </animated.div>
            ) : null}
        </>
    );
};